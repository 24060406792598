<template>
  <div class="content-wrapper">
    <div class="columns is-multiline">
      <div v-if="newsroom.videos.length > 0" class="company-page-block column is-12">
        <video-from-service
          v-for="video in newsroom.videos"
          :key="video.id"
          :video="video"
        />
      </div>

      <div class="company-page-block column is-12">
        <newsroom-edit-social-links-data-provider
          #default="{ handles }"
          :newsroom-id="newsroom.id"
        >
          <newsroom-social-links-embeds :handles="handles" :newsroom-id="newsroom.id" />
        </newsroom-edit-social-links-data-provider>
      </div>
    </div>
  </div>
</template>

<script>
import NewsroomChild from '@/pages/newsroom/NewsroomChild'
import VideoFromService from '@/components/newsroom/VideoFromService.vue'
import NewsroomSocialLinksEmbeds from '@/components/newsroom/NewsroomSocialLinksEmbeds'
import NewsroomEditSocialLinksDataProvider from '@/components/newsroomEdit/NewsroomEditSocialLinksDataProvider'

export default {
  components: {
    NewsroomEditSocialLinksDataProvider,
    NewsroomSocialLinksEmbeds,
    VideoFromService
  },

  extends: NewsroomChild,

  data () {
    return {
      pageName: 'About'
    }
  }
}
</script>
